<template>
  <div class="detail">
    <div class="content">
      <iframe name = "child" id = "child" width="100%" height="100vh" frameborder="0" scrolling="yes"
          :src="info.previewUrl"
      ></iframe>
    </div>
    <div class="wxb-btn wxb-btn-fix">
      <div class="confirm-btn" @click="share">分享</div>
    </div>
    <div class="share-tip" v-if="showShareTip">
      <img class="share-tip-icon" src="../../assets/images/share_tip.png" alt="">
    </div>

  </div>
</template>

<script>
  import home from '../../api/home';
  import task from '@/api/task';
  import {Toast} from 'vant';

  export default {
    name: 'detail',
    data() {
      return {
        executeWay: '',
        viewUrl: '',
        taskId: '',
        info: {},
        shareUrl: '',
        showShareTip: false,
      };
    },
    methods: {
      //上报传播任务分享好友，发圈操作
      async reportShare(shareType) {
        let params={
          shareType,
          estateId: localStorage.getItem('platform_estateId'),
          taskId: this.taskId
        };
        console.log(params, '上报参数');
        let {code}=await task.reportShare(params);
        if (code==='0') {
          console.log('上报成功');
        }
      },
      async share() {
        if (!this.taskId) {
          Toast('分享失败');
          return false;
        }
        this.showShareTip = true;
      },
      async getDetail() {
        let { data } = await home.getTaskDetail({taskId: this.taskId});
        this.info = data;
      },
      async getUrl() {
        let params = {};
        params['opId'] = localStorage.getItem('platform_opId');
        params['estateId'] = localStorage.getItem('platform_estateId');
        params['entityId'] = this.taskId;
        params['entityType'] = 6;
        let { data } = await home.getCmeUrl(this.info.contentId, params);
        this.shareUrl = data;
        console.log('this.shareUrl', this.shareUrl);
      },
      shareChange() {
        this.$wx.onMenuShareAppMessage({
          title: this.info.title, // 分享标题
          desc: this.info.guideAbstract ? this.info.guideAbstract:'查看详情', // 分享描述
          link: this.shareUrl, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: this.info.imgUrl, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('转发---res', res);
            this.showShareTip = false;
            Toast('分享成功');
          },
          cancel: ()=> {
            this.showShareTip = false;
            // 用户取消分享后执行的回调函数
          }
        });
        this.$wx.onMenuShareWechat({
          title: this.info.title, // 分享标题
          desc: this.info.guideAbstract ? this.info.guideAbstract:'查看详情', // 分享描述
          link: this.shareUrl, // 分享链接
          imgUrl: this.info.imgUrl, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('微信---res', res);
            this.showShareTip = false;
            Toast('分享成功');
            if (this.executeWay.indexOf('2')!==-1) {
              this.reportShare(2);
            }
          },
          cancel: ()=> {
            this.showShareTip = false;
            // 用户取消分享后执行的回调函数
          }
        });
        this.$wx.onMenuShareTimeline({
          title: this.info.title, // 分享标题
          desc: this.info.guideAbstract ? this.info.guideAbstract:'查看详情', // 分享描述
          link: this.shareUrl, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: this.info.imgUrl, // 分享图标
          success: (res)=> {
            // 用户确认分享后执行的回调函数
            console.log('分享到朋友圈--res', res);
            this.showShareTip = false;
            Toast('分享成功');
            if (this.executeWay.indexOf('1')!==-1) {
              this.reportShare(1);
            }
          },
          cancel: ()=> {
            this.showShareTip = false;
            // 用户取消分享后执行的回调函数
          }
        });
      }
    },
    async mounted() {
      this.$wx.hideMenuItems({
        menuList: ['menuItem:setFont', 'menuItem:share:email', 'menuItem:openWithSafari', 'menuItem:favorite', 'menuItem:copyUrl', 'menuItem:openWithSafari', 'menuItem:share:email'] // 要隐藏的菜单项
      });
      this.taskId = this.$route.query.taskId;
      this.executeWay=this.$route.query.executeWay;
      await this.getDetail();
      await this.getUrl();
      this.shareChange();
    }
  };
</script>

<style lang="less" scoped>
.content{
  width: 100%;
  height: calc(100vh - 44px - 4px - env(safe-area-inset-bottom));
  overflow: auto;
}
.share-tip{
  position: fixed;
  top: 4px;
  right: 8px;
  z-index: 3;
  width: 236px;
  height: 86px;
  img{
    width: 236px;
  }
}
iframe {
  display: block;
  background: #fff;
  border: none;
  height: 100%;
  width: 100vw;
  pointer-events: none;
}
</style>
